<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>

      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@15"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-3">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <v-row dense>
        <v-col>
          <v-tabs v-model="tab" align-with-title @change="handleChangeTab">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab
            ><v-icon class="mr-3">mdi-warehouse</v-icon> Dados Gerais
            </v-tab>
            <v-tab> <v-icon class="mr-3">mdi-call-split</v-icon> Splits </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <form @submit.prevent="handleSave">
        <v-row>
          <v-col>
            <v-tabs-items v-model="tab" style="min-height: 370px">
              <v-container fluid>
                <!-- Dados Gerais -->
                <v-tab-item>
                  <v-row style="padding: 2rem;">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2">
                          <v-select
                              :readonly="mode == 'view'"
                              required
                              dense
                              :items="status"
                              item-text="texto"
                              item-value="valor"
                              label="Status"
                              v-model="estande.status"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                              :readonly="mode == 'view'"
                              dense
                              :items="eventos"
                              item-text="texto"
                              item-value="valor"
                              label="Evento"
                              v-model="estande.evento"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="7">
                          <v-text-field
                              :readonly="mode == 'view'"
                              required
                              dense
                              label="Nome"
                              v-model="estande.nome"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-card elevation="0">
                        <v-row disabled>
                          <v-col v-if="mode !== 'view'" cols="6">
                            <TabView class="tabview-custom">
                              <TabPanel header="Serviços">
                                <v-row>
                                  <div class="espacado">
                                    <Chips
                                        ref="chipcomponente"
                                        v-model="filtros.servico"
                                        separator=","
                                        @add="getServicos(true)"
                                        @remove="getServicos(true)"
                                        placeholder="Filtro por SKU, nome e descrição "
                                    />
                                  </div>
                                  <div class="espacado">
                                    <BotaoBuscarListas @buscar="buscarServicos" />
                                  </div>
                                </v-row>
                                <v-row>
                                  <v-col>
                                    <DataTable
                                        :value="servicos"
                                        @row-select="onServicoSelect"
                                        selectionMode="single"
                                        dataKey="id"
                                    >
                                      <Column
                                          field="sku"
                                          header="SKU"
                                          :styles="{ width: '6rem' }"
                                      >
                                        <template #body="slot">
                                          <p class="sku">{{ slot.data.sku }}</p>
                                        </template>
                                      </Column>
                                      <Column field="nome" header="Nome"></Column>
                                      <Column
                                          field="valor"
                                          header="Valor"
                                          :styles="{ width: '6rem' }"
                                      >
                                        <template #body="slot">
                                          {{
                                            slot.data.valor
                                                | formatMoney({ minimumFractionDigits: 2 })
                                          }}
                                        </template>
                                      </Column>
                                    </DataTable>
                                  </v-col>
                                </v-row>
                                <v-pagination
                                    class="mt-2"
                                    v-model="pagination.page"
                                    :length="totalPaginas"
                                    @input="nextServico"
                                    :total-visible="10"
                                    :loading="carregando"
                                ></v-pagination>
                              </TabPanel>
                              <TabPanel header="Produtos">
                                <v-row>
                                  <div class="espacado">
                                    <Chips
                                        ref="chipcomponente"
                                        v-model="filtros.produto"
                                        separator=","
                                        @add="getProdutos(true)"
                                        @remove="getProdutos(true)"
                                        placeholder="Filtro por nome, código e descrição"
                                    />
                                  </div>
                                  <div class="espacado">
                                    <BotaoBuscarListas @buscar="buscarProdutos" />
                                  </div>
                                </v-row>

                                <v-row>
                                  <v-col>
                                    <DataTable
                                        :value="produtos"
                                        @row-select="onProdutoSelect"
                                        selectionMode="single"
                                        dataKey="id"
                                    >
                                      <Column
                                          field="codigo_barras"
                                          header="SKU"
                                          :styles="{ width: '6rem' }"
                                      >
                                        <template #body="slot">
                                          <p class="sku">{{ slot.data.codigo_barras }}</p>
                                        </template>
                                      </Column>
                                      <Column field="nome" header="Nome"></Column>
                                      <Column
                                          field="valor"
                                          header="Valor"
                                          :styles="{ width: '6rem' }"
                                      >
                                        <template #body="slot">
                                          {{
                                            slot.data.valor
                                                | formatMoney({ minimumFractionDigits: 2 })
                                          }}
                                        </template>
                                      </Column>
                                    </DataTable>
                                  </v-col>
                                </v-row>
                                <v-pagination
                                    class="mt-2"
                                    v-model="pagination.page"
                                    :length="totalPaginas"
                                    @input="nextProduto"
                                    :total-visible="10"
                                    :loading="carregando"
                                ></v-pagination>
                              </TabPanel>
                            </TabView>
                          </v-col>
                          <v-col :cols="mode !== 'view' ? 6 : 12" class="mt-12">
                            <DataTable
                                :class="mode === 'view' ? 'mt-n10' : ''"
                                :value="itens"
                                responsiveLayout="stack"
                                breakpoint="960px"
                            >
                              <Column header="Item">
                                <template #body="slot">
                                  <div v-if="slot.data.servico">
                                    <span>{{ slot.data.servico.nome }}</span><br/>
                                    <span v-if="slot.data.servico.descricao" class="text-caption">
                            <span v-if="slot.data.servico.descricao. length > 30">
                              {{ slot.data.servico.descricao.slice(0, 30) }}...
                            </span>
                            <span v-else>
                              {{ slot.data.servico.descricao }}
                            </span>
                          </span>
                                  </div>
                                  <div v-if="slot.data.produto">
                                    <span>{{ slot.data.produto.nome }}</span><br/>
                                    <span v-if="slot.data.produto.descricao" class="text-caption">
                            <span v-if="slot.data.produto.descricao.length > 30">
                              {{ slot.data.produto.descricao.slice(0, 30) }}...
                            </span>
                            <span v-else>
                              {{ slot.data.produto.descricao }}
                            </span>
                          </span>
                                  </div>
                                </template>
                              </Column>

                              <Column
                                  header="Valor unitário"
                                  :styles="{ width: '9rem' }"
                              >
                                <template #body="slot">
                                  R$ {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2, }) }}
                                </template>
                              </Column>

                              <Column v-if="mode !== 'view'" header="Remover" :styles="{ width: '1rem' }">
                                <template #body="slot">
                                  <Button
                                      icon="pi pi-trash"
                                      class="p-button-rounded p-button-outlined"
                                      @click="() => removeItem(slot.data)"
                                  />
                                </template>
                              </Column>
                            </DataTable>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- Split -->
                <v-tab-item>
                  <v-row class="d-flex justify-center">
                    <v-col>
                      <v-row dense>
                        <v-col>
                          <span class="text-h5"> Splits </span>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="!user.permissoes.adm_split">
                        <v-col>
                          <v-alert colored-border type="info" elevation="2">
                            Seu usuário não possui permissão para configurar
                            splits, clique em avançar para pular esta etapa.
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split && mode !== 'view'">
                        <v-col cols="10">
                          <v-autocomplete
                              :items="empresasMarketPlace"
                              item-value="id"
                              item-text="razao_social"
                              v-model="destinatarioSplit"
                              label="Destinatarios"
                              dense
                              return-object
                          >
                            <template v-slot:selection="{ item }">
                            <span>
                              {{ $masks.cnpj(item.cnpj) }} -
                              {{ item.razao_social }}
                            </span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{
                                    item.nome_fantasia
                                  }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ $masks.cnpj(item.cnpj) }} -
                                  {{ item.razao_social }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  @click="addSplit"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar Split</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left" width="450">Empresa</th>
                                <th class="text-left" width="120">
                                  Percentual
                                </th>
                                <th class="text-left">Paga Taxa</th>
                                <th class="text-left">Responsavel Divisão</th>
                                <th v-if="mode === 'create'" class="text-left">Remover</th>
                              </tr>
                              </thead>
                              <tbody v-if="estande">
                              <tr
                                  v-for="(split, index) in estande.splits"
                                  :key="split.id"
                              >
                                <td>
                                  <div>
                                    <div>
                                      {{ split.recebedor.cnpj }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div>
                                      {{ split.recebedor.razao_social }}
                                    </div>
                                    <div>
                                      <span class="endereco">{{
                                          split.recebedor.enderecoCompleto
                                        }}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                      :disabled="mode === 'view'"
                                      mode="decimal"
                                      :minFractionDigits="2"
                                      :maxFractionDigits="2"
                                      v-model="split.valor"
                                      suffix=" %"
                                      :max="100"
                                      :min="0"
                                      :allowEmpty="false"
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      :disabled="mode === 'view'"
                                      v-model="split.paga_taxa_transacao"
                                      :label="
                                      split.paga_taxa_transacao ? 'Sim' : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      :disabled="mode === 'view'"
                                      v-model="split.paga_resto_divisao_taxa"
                                      :label="
                                      split.paga_resto_divisao_taxa
                                        ? 'Sim'
                                        : 'Não'
                                    "
                                  />
                                </td>
                                <td v-if="mode !== 'view'">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          text
                                          small
                                          @click="
                                          openModalRemoveSplit(split, index)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remover Item</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex align-self-center">
                      <v-progress-circular
                          :rotate="360"
                          :size="200"
                          :width="10"
                          :value="totalPercSplit"
                          :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                      >
                      <span class="text-h5 font-weight-bold">
                        {{ totalPercSplit }}%
                      </span>
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-container>
            </v-tabs-items>
            <v-row>
              <v-col class="text-end mr-15">
                <v-btn
                    dark
                    color="primary"
                    v-if="mode != 'view'"
                    type="submit"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CryptoJS from "crypto-js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";

export default {
  components: {Button, BotaoBuscarListas, Chips, TabView, TabPanel, Column, DataTable, InputNumber},
  props: {
    mode: String,
    idEstande: String,
    backRoute: String,
    idEmpresa: String
  },
  data() {
    return {
      tab: 0,
      loadingSkeleton: false,
      imagem: null,
      estande: {
        status: null,
        evento: null,
        servicos: [],
        produtos: [],
        splits: [],
        nome: null
      },
      status: [
        {
          texto: "ATIVO",
          valor: "ATIVO"
        }
      ],
      eventos: [],
      filtros: {
        produto: [],
        servico: [],
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 5,
        first: 0
      },
      carregando: false,
      servicos: [],
      produtos: [],
      itens: [],
      empresasMarketPlace: [],
      destinatarioSplit: null,
    };
  },
  mounted() {
    this.getServicos();
    this.getProdutos();
    this.getEventos();
    if (this.mode != "create") this.getEstande();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    totalPercSplit() {
      if (this.estande == null || !this.estande.splits) return 0;

      const total = this.estande.splits
          .reduce((accumulator, obj) => {
            return accumulator + this.parseToFloat(obj.valor ? obj.valor : 0);
          }, 0.0)
          .toFixed(2);

      return total == 100.0 ? 100 : total;
    },
    checkSplit() {
      if (!this.user.permissoes.adm_split) return { msg: "", check: true };

      if (this.estande.splits == null || this.estande.splits.length == 0) return { msg: '', check: true };

      if (this.totalPercSplit > 100.0 || this.totalPercSplit < 100.0) {
        return { msg: `A % tem que estar em 100%!`, check: false };
      }
      let pagaTaxa = false;
      let pagaDivisao = false;
      this.estande.splits.forEach(split => {
        pagaTaxa = split.paga_taxa_transacao ? true : pagaTaxa;
        pagaDivisao = split.paga_resto_divisao_taxa ? true : pagaDivisao;
      });

      if (!pagaTaxa || !pagaDivisao) return { msg: `Preencha Paga Taxa e Responsável Divisão!`, check: false };

      return { msg: '', check: true };
    },
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getEstande() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/estande/${this.idEstande}`)
        .then(response => {
          this.estande = response.data;

          if(this.estande.evento) this.estande.evento = this.estande.evento.id;

          this.itens = [...this.estande.produtos, ...this.estande.servicos];

          const produtos = this.estande.produtos.map((element) => {
            return { produto: element, valor: element.valor }
          });

          const servicos = this.estande.servicos.map((element) => {
            return { servico: element, valor: element.valor }
          });

          this.itens = [...produtos, ...servicos];

          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Estande",
            text: error.response.data
          });
        });
    },
    async handleSave() {
      const checkSplit = this.checkSplit;

      if(!checkSplit.check) {
        this.$toast.add({
          severity: "error",
          summary: checkSplit.msg,
          life: 2000
        });
        return;
      }

      this.mode === "create" ? await this.save() : await this.edit();
    },
    nextServico(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getServicos();
    },
    nextProduto(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getProdutos();
    },
    onServicoSelect(event) {
      const findItem = this.itens.some(
          item => item.servico != null && item.servico.id === event.data.id
      );

      if (findItem) {
        this.$toast.add({
          severity: "warn",
          summary: `Serviço ${event.data.nome} já foi adicionado!`,
          life: 2000
        });
        return;
      }

      const itemData = {
        servico: event.data,
        produto: null,
        valor: Number(parseFloat(event.data.valor).toFixed(2))
      };

      this.itens.push(itemData);
      this.estande.servicos.push({ id: itemData.servico.id });

      this.$toast.add({
        severity: "success",
        summary: `Serviço ${event.data.nome} adicionado!`,
        life: 2000
      });
    },
    onProdutoSelect(event) {
      const findItem = this.itens.some(
          item => item.produto != null && item.produto.id === event.data.id
      );
      if (findItem) {
        this.$toast.add({
          severity: "warn",
          summary: `Produto ${event.data.nome} já foi adicionado!`,
          life: 2000
        });
        return;
      }

      const itemData = {
        produto: event.data,
        servico: null,
        valor: Number(parseFloat(event.data.valor).toFixed(2))
      };

      this.itens.push(itemData);
      this.estande.produtos.push({ id: itemData.produto.id });

      this.$toast.add({
        severity: "success",
        summary: `Produto ${event.data.nome} adicionado!`,
        life: 2000
      });
    },
    async getProdutos(filtro = false) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      await this.$axios
        .get(`/produto/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              produto: this.filtros.produto.map(s => s.trim()),
              status: ["ATIVO"]
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(async response => {
          this.produtos = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar produtos",
            text: error.response.data
          });
        });
    },
    async getServicos(filtro = false) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      await this.$axios
        .get(`/servico/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              servico: this.filtros.servico.map(s => s.trim()),
              status: ["ATIVO"]
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(async response => {
          this.servicos = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar serviços",
            text: error.response.data
          });
        });
    },
    removeItem(item) {
      this.itens.splice(this.itens.indexOf(item), 1);
      this.$toast.add({
        severity: "info",
        summary: `Item ${
            item.servico ? item.servico.nome : item.produto.nome
        } removido!`,
        life: 2000
      });
    },
    getEventos() {
      return this.$axios
        .get(`/evento/empresa/${this.empresaAtual.id}/listar`,{
          params: {
            first: 0,
            limit: 900000,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.eventos = response.data.data;

          this.eventos = this.eventos.map((element) => {
            return { texto: element.nome, valor: element.id }
          })

        });
    },
    buscarServicos() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.servico.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
    buscarProdutos() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.produto.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
    async edit() {
      const estandeObj = { ...this.estande };

      if(estandeObj.evento) {
        estandeObj.evento = { id: estandeObj.evento };
      } else {
        delete estandeObj.evento
      }

      //estandeObj.itens = this.itens;
      estandeObj.empresa = { id: this.empresaAtual.id };

      const produtos = this.itens
        .filter((item) => item.produto !== undefined && item.produto !== null)
        .map((item) => item.produto);

      const servicos = this.itens
        .filter((item) => item.servico !== undefined && item.servico !== null)
        .map((item) => item.servico);

      estandeObj.produtos = produtos.map((element) => {
        return { id: element.id };
      });
      estandeObj.servicos = servicos.map((element) => {
        return { id: element.id };
      });
      estandeObj.id = this.idEstande;

      await this.$axios
          .post("/estande", estandeObj)
          .then(() => {

            this.$toast.add({
              severity: "success",
              summary: `Estande editado com sucesso.`,
              life: 2000
            });

            this.$router.push({
              name: "Estandes",
            });

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Editar Estande",
              text: error.response.data
            });
          });
    },
    async save() {

      const estandeObj = { ...this.estande };

      if(estandeObj.evento) {
        estandeObj.evento = { id: estandeObj.evento };
      } else {
        delete estandeObj.evento
      }

      estandeObj.empresa = { id: this.empresaAtual.id };

      await this.$axios
        .post("/estande", estandeObj)
        .then((response) => {

          this.$toast.add({
            severity: "success",
            summary: `Estande cadastrado com sucesso.`,
            life: 2000
          });

          this.$router.push({
            name: "Estandes",
          });

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Salvar Estande",
              text: error.response.data
            });
          });
    },
    async getEmpresasMarketPlace() {
      if (!this.empresaAtual || !this.empresaAtual.id) {
        this.empresasMarketPlace = [];
      }

      return this.$axios
          .get(`/empresa/${this.empresaAtual.id}/marketplace?status=ATIVA`)
          .then(response => {
            this.empresasMarketPlace = response.data.map(empresa => {
              empresa.telefone = this.$masks.telefone(empresa.telefone);
              empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
              return empresa;
            });
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Consultar Empresas",
              text: error.response.data
            });
          });
    },
    handleChangeTab() {
      if (this.tab === 1) this.getEmpresasMarketPlace();
    },
    addSplit() {
      if (!this.destinatarioSplit || !this.destinatarioSplit.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Selecione a empresa de destino do split"
        });
        return;
      }

      const findSplit = this.estande.splits.some(
          split => split.recebedor.id === this.destinatarioSplit.id
      );
      if (findSplit) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Empresa já está adicionada à lista de splits."
        });
        return;
      }
      const splitData = {
        recebedor: {
          id: this.destinatarioSplit.id,
          razao_social: this.destinatarioSplit.razao_social,
          nome_fantasia: this.destinatarioSplit.nome_fantasia,
          cnpj: this.destinatarioSplit.cnpj,
          enderecoCompleto: this.destinatarioSplit.enderecoCompleto
        },
        valor: 0,
        metodo: "PERCENTUAL",
        paga_taxa_transacao: this.destinatarioSplit.id == this.empresaAtual.id,
        paga_resto_divisao_taxa:
            this.destinatarioSplit.id == this.empresaAtual.id
      };
      this.estande.splits.push(splitData);
      this.destinatarioSplit = null;
    },
    removeSplit(index) {
      this.estande.splits.splice(index, 1);
      this.closeDialogConfirm();
    },
    openModalRemoveSplit(split, index) {
      this.openDialogConfirm({
        title: "Remover Split",
        text: `Deseja realmente o split da empresa ${split.recebedor.razao_social}?`,
        confirma: this.removeSplit.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },
  }
};
</script>

<style scoped>
@media (max-width: 900px) {
  .col,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    flex: none;
    max-width: 25rem;
  }
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
