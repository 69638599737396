<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-row dense class="mx-auto">
        <v-col cols="9">
          <v-card-title class="headline">{{ estoque.id ? 'Editar Estoque' : 'Adicionar Novo Estoque' }}</v-card-title>
        </v-col>
        <v-col class="text-right" cols="3">
          <v-btn
            fab
            small
            text
            @click="fechar"
            class="ma-2"
          >
            <v-icon color="grey darken-1">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-form ref="estoqueForm" @submit.prevent="salvar">
        <v-card-text>
          <v-text-field
            label="Nome do Estoque"
            v-model="estoque.nome"
            required
          ></v-text-field>
          <v-switch
            label="É o estoque principal?"
            v-model="estoque.principal"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btCancela" text @click="fechar">
            Cancelar
          </v-btn>
          <v-btn color="btConfirma" text type="submit"> Salvar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    estoqueParaEdicao: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      estoque: {
        nome: "",
        principal: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"])
  },
  watch: {
    // Atualiza os campos do estoque com os dados recebidos para edição
    estoqueParaEdicao: {
      handler(newVal) {
        if (newVal) {
          this.estoque = { ...newVal };
        }
      },
      immediate: true
    },
  },
  methods: {
    fechar() {
      this.$emit("update:dialog", false); // Fechar o diálogo
      this.limparCampos(); // Limpa os campos da modal
    },
    async salvar() {
      this.estoque.empresa = { id: this.empresaAtual.id };
      return await this.$axios
        .post(`/estoque`, this.estoque)
        .then(() => {
          this.$emit("saveDialogEstoqueLoja");
          this.fechar();
        })
        .catch(error => {
          console.log(error);
        });
    },
    limparCampos() {
      this.estoque = { nome: "", principal: false }; // Limpa o estoque para nova criação
    }
  }
};
</script>
