<template>
  <div>
    <div class="mt-4">
      <img :src="getLogoImage" class="menuLogo" alt="" />
    </div>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item @click="$router.push('/')" link>
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/vendas-pdv')" link>
        <v-list-item-icon>
<!--          <i class="fas fa-laptop fa-menu-icon"></i>-->
          <v-icon>mdi-laptop</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Vendas PDV</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/cobrancas')" link>
        <v-list-item-icon>
<!--          <i class="fa-menu-icon fas fa-file-invoice-dollar"></i>-->
          <v-icon>mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cobranças</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/assinaturas')" link>
        <v-list-item-icon>
<!--          <i class="fas fa-clipboard-list fa-menu-icon"></i>-->
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Assinaturas</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/contratos')" link>
        <v-list-item-icon>
<!--          <i class="fas fa-clipboard-list fa-menu-icon"></i>-->
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Contratos</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/transacoes')" link>
        <v-list-item-icon>
          <v-icon>mdi-bank-transfer</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Transações</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/mesa-antecipacao')" link>
        <v-list-item-icon>
          <v-icon>mdi-credit-card</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Antecipação</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/notasfiscais')" link>
        <v-list-item-icon>
<!--          <i class="fa-menu-icon fas fa-file-invoice"></i>-->
          <v-icon>mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Notas Fiscais</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/remessas')" link>
        <v-list-item-icon>
<!--          <i class="fa-menu-icon fas fa-file-archive"></i>-->
          <v-icon>mdi-archive</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Remessas</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/estoque')" link>
        <v-list-item-icon>
          <!--          <i class="fa-menu-icon fas fa-file-archive"></i>-->
          <v-icon>mdi-warehouse</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Controle de Estoque</v-list-item-title>
      </v-list-item>

      <v-list-group
          :value="false"
      >
        <template v-slot:activator>
          <v-list-item-title>Relatórios</v-list-item-title>
        </template>

        <template v-slot:prependIcon>
<!--          <i class="fa-menu-icon fas fa-list-alt"></i>-->
          <v-icon> mdi-format-list-bulleted </v-icon>
        </template>

        <template v-slot:appendIcon>
          <v-icon> mdi-menu-down </v-icon>
        </template>

        <v-list-item v-if="user && user.permissoes.extrato_conta" class="ml-3" @click="$router.push('/relatorios/bank')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-university fa-menu-icon"></i>-->
            <v-icon> mdi-bank </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Extrato conta</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/monitoramento')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-chart-bar fa-menu-icon"></i>-->
            <v-icon> mdi-chart-bar </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Monitoramento</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/extrato-recebiveis')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-receipt fa-menu-icon"></i>-->
            <v-icon> mdi-receipt </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Extrato Recebíveis</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/vendas-estande')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-home fa-menu-icon"></i>-->
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Vendas por Estande</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/pagamentoAnalitico')" link>
          <v-list-item-icon>
            <!--            <i class="fas fa-chart-bar fa-menu-icon"></i>-->
            <v-icon> mdi-chart-bar </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pagamento Analítico</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/vendas-analitico')" link>
          <v-list-item-icon>
            <!--            <i class="fas fa-chart-bar fa-menu-icon"></i>-->
            <v-icon> mdi-sale </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Vendas Analítico</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/relatorios/vendasPDV')" link>
          <v-list-item-icon>
            <v-icon> mdi-laptop </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Vendas PDV</v-list-item-title>
        </v-list-item>

      </v-list-group>

      <v-list-group
          :value="false"
      >
        <template v-slot:activator>
          <v-list-item-title>Cadastros</v-list-item-title>
        </template>

        <template v-slot:prependIcon>
          <v-icon> mdi-plus </v-icon>
        </template>

        <template v-slot:appendIcon>
          <v-icon> mdi-menu-down </v-icon>
        </template>

        <v-list-item class="ml-3" @click="$router.push('/empresas')" link>
          <v-list-item-icon>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Empresas</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/clientes')" link>
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Clientes</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/servicos')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-handshake fa-menu-icon"></i>-->
            <v-icon> mdi-vector-union </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Serviços</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/produtos')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-cubes fa-menu-icon"></i>-->
            <v-icon> mdi-package-variant-closed </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Produtos</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/categorias')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-cogs fa-menu-icon"></i>-->
            <v-icon> mdi-settings </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Categorias</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/setores')" link>
          <v-list-item-icon>
<!--            <i class="fas fa-cogs fa-menu-icon"></i>-->
            <v-icon> mdi-settings </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Setores</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/planos')" link>
          <v-list-item-icon>
            <v-icon>mdi-alpha-p-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Planos</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/eventos')" link>
          <v-list-item-icon>
            <v-icon>mdi-eventbrite</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Eventos</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/estandes')" link>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Estandes</v-list-item-title>
        </v-list-item>

        <v-list-item class="ml-3" @click="$router.push('/usuarios')" link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item>

      </v-list-group>

    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user", "config"]),
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    }
  }
};
</script>

<style>
.menuLogo {
  width: 100%;
  height: auto;
  padding: 0 1rem 0 1rem;
}
.fa-menu-icon {
  font-size: 1.2em;
  color: #757575;
  margin: auto auto;
}
.fa-sub-menu-icon {
  font-size: 0.9em;
  color: #757575;
  margin: auto auto;
}
</style>
