import { RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Estoque from "@/views/Estoque/Estoque.vue";
// import CriarEstoque from "@/views/Estoque/CriarEstoque.vue"; // Comente esta linha até o arquivo CriarEstoque.vue ser criado

const estoqueRoutes: RouteConfig = {
  path: "/estoque",
  component: GenericComponent,
  children: [
    {
      path: "/",
      component: Estoque,
      name: "Estoque",
      meta: {
        breadCrumb: [
          {
            text: "Controle de Estoque"
          }
        ]
      }
    },
    // Comente as rotas abaixo até que os arquivos sejam criados

    // {
    //   path: "/estoque/add",
    //   name: "addEstoque",
    //   component: CriarEstoque,
    //   props: {
    //     mode: "create"
    //   },
    //   meta: {
    //     breadCrumb(route: Route) {
    //       return [
    //         {
    //           text: "Controle de Estoque",
    //           to: {
    //             name: "Estoque"
    //           }
    //         },
    //         {
    //           text: "Novo Item"
    //         }
    //       ];
    //     }
    //   }
    // },
    // {
    //   path: "/estoque/:idItem/edit",
    //   name: "editEstoque",
    //   component: CriarEstoque,
    //   props: route => ({
    //     mode: "edit",
    //     idItem: route.params.idItem
    //   }),
    //   meta: {
    //     breadCrumb(route: Route) {
    //       const idItem = route.params.idItem;

    //       return [
    //         {
    //           text: "Controle de Estoque",
    //           to: { name: "Estoque" }
    //         },
    //         {
    //           text: idItem
    //         },
    //         {
    //           text: "Editar"
    //         }
    //       ];
    //     }
    //   }
    // },
    // {
    //   path: "/estoque/:idItem",
    //   name: "viewEstoque",
    //   component: CriarEstoque,
    //   props: route => ({
    //     mode: "view",
    //     idItem: route.params.idItem
    //   }),
    //   meta: {
    //     breadCrumb(route: Route) {
    //       const idItem = route.params.idItem;

    //       return [
    //         {
    //           text: "Controle de Estoque",
    //           to: { name: "Estoque" }
    //         },
    //         {
    //           text: idItem
    //         }
    //       ];
    //     }
    //   }
    // }
  ]
};

export default estoqueRoutes;
