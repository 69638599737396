<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-row dense class="mx-auto">
        <v-col cols="9">
          <v-card-title class="headline">Tipo de movimentação</v-card-title>
          <v-card-subtitle>Qual o tipo de movimentação do estoque?</v-card-subtitle>
        </v-col>
        <v-col class="text-right" cols="3">
          <v-btn fab small text @click="fechar" class="ma-2">
            <v-icon color="grey darken-1">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row class="mx-auto">
          <v-col cols="6">
            <v-btn @click="handleSaida" block outlined color="red">
              <v-icon left color="red">mdi-minus-circle-outline</v-icon>
              SAÍDA
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="handleEntrada" block outlined color="btConfirma">
              <v-icon left color="btConfirma">mdi-plus-circle-outline</v-icon>
              ENTRADA
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  methods: {
    fechar() {
      this.$emit("update:dialog", false);
    },
    handleEntrada() {
      this.$emit("handleMovimentacao", "ENTRADA");
    },
    handleSaida() {
      this.$emit("handleMovimentacao", "SAIDA");
    }
  }
};
</script>
