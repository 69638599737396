<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-row dense class="mx-auto">
        <v-col cols="9">
          <v-card-title class="headline">Importar Arquivo CSV</v-card-title>
        </v-col>
        <v-col class="text-right" cols="3">
          <v-btn fab small text @click="fechar" class="ma-2">
            <v-icon color="grey darken-1">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-file-input
          v-model="arquivoCSV"
          label="Selecione o arquivo CSV"
          accept=".csv"
          outlined
        ></v-file-input>
        <p class="text-caption mt-3">
          O arquivo deve conter as colunas: <strong>Código de Barras</strong> ; <strong>Quantidade</strong>.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="btCancela" text @click="fechar">
          Cancelar
        </v-btn>
        <v-btn :disabled="!arquivoCSV" color="btConfirma" text @click="importar">
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      arquivoCSV: null
    };
  },
  methods: {
    fechar() {
      this.$emit("update:dialog", false);
      this.arquivoCSV = null;
    },
    async importar() {
      if (!this.arquivoCSV) return;

      try {
        const conteudoCSV = await this.lerArquivoCSV();
        this.$emit("importarCSV", conteudoCSV);
        this.fechar();
      } catch (error) {
        console.error("Erro ao processar o arquivo CSV:", error);
        this.$emit("erroImportarCSV", error.message);
      }
    },
    lerArquivoCSV() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const conteudo = event.target.result;
          const linhas = conteudo.split("\n").map((linha) => linha.trim());
          const dados = linhas
            .filter((linha) => linha)
            .map((linha) => {
              const [codigo_barras, quantidade] = linha.split(";");
              return { codigo_barras, quantidade: Number(quantidade) };
            });
          resolve(dados);
        };
        reader.onerror = () => reject("Erro ao ler o arquivo CSV.");
        reader.readAsText(this.arquivoCSV);
      });
    }
  }
};
</script>
